import './index.css';
import image from './emma.jpg'

function P(props) {
  return <p className="font-body text-xl mb-4">{props.children}</p>
}

// function H2(props) {
//   return <h2 className="font-display text-2xl md:text-4xl mb-2 md:mb-4">{props.children}</h2>
// }

// function H3(props) {
//   return <h3 className="font-display text-xl md:text-3xl mb-2 md:mb-4">{props.children}</h3>
// }

function App() {
  return (
    <div className="App">
      <header className="App-header">
      </header>
      <main>
        <section className="min-h-screen w-full flex items-center px-8">
          <div className="container mx-auto flex flex-col">
            <div className="w-full lg:w-2/4">
              <h1 className="font-display text-4xl md:text-6xl mb-2 md:mb-4">Hi! I'm Emma Klint.</h1>
              <P>I’m a <span className="font-semibold">leader, designer and developer</span> who is passionate about gender equality, especially in tech, and how the internet and other digital tools shape our society and us as individuals. I want to design and build tools to help people in their everyday life while pushing the boundaries of tech and design.</P>
              <P>Currently, I’m working as a Product Designer at <a href="http://www.mentimeter.com" target="_blank" rel="noreferrer" className="hover:underline">Mentimeter</a> in Stockholm.</P>
              <P>Find me on <a href="https://www.linkedin.com/in/emmaklint/" target="_blank" rel="noreferrer" className="hover:underline">Linkedin</a> or contact me at <a href="mailto:klint.emma@gmail.com" target="_blank" rel="noreferrer" className="hover:underline">klint.emma@gmail.com</a>.</P>
              <img src={image} className="w-full md:w-3/4 rounded" alt="Emma Klint"/>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
}

export default App;
